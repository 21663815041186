import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from './loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})

export class LoaderComponent implements OnInit, OnDestroy {
  isLoading = true;
  subscription: Subscription;
  constructor(private loaderService: LoaderService) {
  }

  ngOnInit() {
    this.subscription = this.loaderService.getLoader().subscribe((v) => {
      this.isLoading = v;
      if (this.isLoading) {
        document.body.classList.add('has-loader');
      } else {
        document.body.classList.remove('has-loader');
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
