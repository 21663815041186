import { CommonModule, HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IKeyboardLayouts, KeyboardClassKey, keyboardLayouts, MatKeyboardModule, MAT_KEYBOARD_LAYOUTS } from 'angular-onscreen-material-keyboard';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { KioskApiInterceptor } from './interceptors/kiosk-api.interceptor';
import { CentsToEurosPipe } from './pipes/cents-to-euros/cents-to-euros.pipe';
import { AuthInterceptor } from './shared/interceptors/auth-interceptor.service';
import { SharedModule } from './shared/shared.module';
import { PassageListComponent } from './widgets/passages/list/passage-list.component';
import { PaymentDialogComponent } from './widgets/passages/payment-dialog/payment-dialog.component';
import { PassageSearchComponent } from './widgets/passages/search/passage-search.component';
import {KioskErrorDialogComponent} from "./widgets/kiosk-error/kiosk-error-dialog/kiosk-error-dialog.component";
import {KioskErrorComponent} from "./widgets/kiosk-error/Kiosk-error.component";

registerLocaleData(localeFr);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const customLayouts: IKeyboardLayouts = {
  ...keyboardLayouts,
  'License Plate': {
    'name': 'License Plate',
    'keys': [
      [
        ['1'],
        ['2'],
        ['3'],
        ['4'],
        ['5'],
        ['6'],
        ['7'],
        ['8'],
        ['9'],
        ['0'],
        [KeyboardClassKey.Bksp]
      ],
      [
        ['A'],
        ['Z'],
        ['E'],
        ['R'],
        ['T'],
        ['Y'],
        ['U'],
        ['I'],
        ['O'],
        ['P']
      ],
      [
        ['-'],
        ['Q'],
        ['S'],
        ['D'],
        ['F'],
        ['G'],
        ['H'],
        ['J'],
        ['K'],
        ['L'],
        ['M']
      ],
      [
        [KeyboardClassKey.Enter],
        ['W'],
        ['X'],
        ['C'],
        ['V'],
        ['B'],
        ['N'],
        ['-'],
        [KeyboardClassKey.Enter]
      ],
      [
        [KeyboardClassKey.Space]
      ]
    ],
    'lang': ['fr']
  }
}

const devProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: KioskApiInterceptor, multi: true },
]

@NgModule({
  declarations: [
    AppComponent,
    CentsToEurosPipe,
    HomeComponent,
    PassageListComponent,
    PassageSearchComponent,
    PaymentDialogComponent,
    KioskErrorDialogComponent,
    KioskErrorComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatKeyboardModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }, defaultLanguage: 'fr'
    }),
  ],
  exports: [TranslateModule],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'fr'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: MAT_KEYBOARD_LAYOUTS,
      useValue: customLayouts
    },
    !environment.production ? devProviders : [],
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
