import {Component, OnDestroy, OnInit} from '@angular/core'
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog"
import {Subscription} from 'rxjs'
import {map} from 'rxjs/operators'
import {
  KioskApiService
} from 'src/app/services/kiosk/kiosk-api.service'
import {KioskErrorDialogComponent, PrinterState} from "./kiosk-error-dialog/kiosk-error-dialog.component";
import {PrinterInfo} from "../../interfaces/printer.info";
import {POSPaperStatus} from "../../interfaces/pos-paper.status";
import {POSCoverStatus} from "../../interfaces/pos-cover.status";
import {POSAutoCutterStatus} from "../../interfaces/pos-auto-cutter.status";

@Component({
  selector: 'app-kiosk-error',
  template: '',
})
export class KioskErrorComponent implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription()

  private dialog: MatDialogRef<KioskErrorDialogComponent> = null

  constructor(
    private kioskApiService: KioskApiService,
    private matDialog: MatDialog,
  ) {
    // do nothing
  }

  ngOnInit(): void {
    this.subscription.add(this.kioskApiService.getPrinterInfos().pipe(
      map(info => this.getPrinterState(info))
    ).subscribe(state => {
      if (state === PrinterState.OK) {
        this.clearError()
      } else {
        this.showError(state)
      }
    }))
  }

  getPrinterState(info: PrinterInfo): PrinterState {
    if (info.cover == POSCoverStatus.OPENED) return PrinterState.COVER_OPENED
    if (info.paper == POSPaperStatus.END) return PrinterState.PAPER_END
    if (info.unrecoverableError || info.autoCutter == POSAutoCutterStatus.ERROR) return PrinterState.ERROR
    if (!info.online) return PrinterState.OFFLINE
    return PrinterState.OK;
  }

  showError(state: PrinterState): void {
    let curDialog = this.dialog
    if (curDialog == undefined || curDialog == null) {
      let config = {
        disableClose: true,
        width: "calc(100vw - 200px)",
        height: "calc(100vh - 200px)",
        panelClass: "amb-dialog",
      } as MatDialogConfig
      curDialog = this.matDialog.open(KioskErrorDialogComponent, config)
      this.dialog = curDialog
    }
    try {
      curDialog.componentInstance.updateState(state)
    } catch (ex) {
      console.error("Unable to show kiosk error", ex)
    }
  }

  clearError(): void {
    if (this.dialog) {
      this.dialog.close()
      this.dialog = null
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
