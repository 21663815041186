import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Passage } from 'src/app/interfaces/passage';
import { PassageService } from "../../../services/passage/passage.service";

@Component({
  selector: 'app-passage-list',
  templateUrl: './passage-list.component.html',
  styleUrls: ['./passage-list.component.scss']
})
export class PassageListComponent implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription()
  passages: Passage[]

  constructor(
    private passageService: PassageService
  ) {
    // do nothing
  }

  ngOnInit(): void {
    this.subscription.add(this.passageService.getLastPassages().subscribe(passages => {
      this.passages = passages as Passage[]
    }))
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
