<form class="passage-search" [formGroup]="searchForm" (ngSubmit)="doPayment()">
  <label i18n>Entrez votre numéro de plaque</label>
  <input type="text" [matKeyboard]="'License Plate'" [darkTheme]="true" #licensePlate formControlName="licensePlate"
    (keyup.enter)="blur()" (enterClick)="blur()" (blur)="search()" placeholder="XX-XXX-XX">
  <div class="passage-search__result">
    <label i18n>Le montant de votre trajet à régler est de</label>
    <span>{{ total == PENDING_TOTAL ? total : (total | centsToEuros : '1.2') }} €</span>
  </div>
  <button mat-button type="submit" [disabled]="total == PENDING_TOTAL" i18n>Payer en CB</button>
</form>
