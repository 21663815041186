import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog"
import * as mustache from 'mustache'
import { Observable, of, Subscription, throwError, zip } from 'rxjs'
import { mergeMap, tap } from 'rxjs/operators'
import { Debit } from 'src/app/interfaces/debit'
import { Order, Product, Transaction } from 'src/app/interfaces/order'
import { CentsToEurosPipe } from 'src/app/pipes/cents-to-euros/cents-to-euros.pipe'
import { KioskApiService } from 'src/app/services/kiosk/kiosk-api.service'
import { PassageService } from 'src/app/services/passage/passage.service'
import { PaymentDialogComponent } from '../payment-dialog/payment-dialog.component'
import {environment} from "../../../../environments/environment";

const TICKET_TEMPLATE = `(% img center '${environment.mediaBaseUrl}/atmb/atmb-logo-lowres.png' %)
(% text center fontB '{{{address}}}' %)
(% text center fontB 'siret: {{{siret}}}' %)
(% text center fontB 'Tel: {{{telNb}}}' %)

ticket n° {{{ticketNb}}}
le {{{ticketDate}}}
Immatriculation: {{{licensePLate}}}
==============================================
{{#products}}
{{{name}}}
{{/products}}
----------------------------------------------
{{{priceNoVAT}}}
{{{price}}}
----------------------------------------------
Règlement: Carte Bancaire`

const TICKET_WIDTH = 47
@Component({
  selector: 'app-passage-search',
  templateUrl: './passage-search.component.html',
  styleUrls: ['./passage-search.component.scss'],
  providers: [CentsToEurosPipe]
})
export class PassageSearchComponent implements OnInit, OnDestroy {

  PENDING_TOTAL = "--"

  subscription: Subscription = new Subscription()

  searchForm: FormGroup
  @ViewChild('licensePlate') licensePlate: ElementRef

  total = this.PENDING_TOTAL

  constructor(
    private kioskApiService: KioskApiService,
    private passageService: PassageService,
    private matDialog: MatDialog,
    private formBuilder: FormBuilder,
    private centsToEurosPipe: CentsToEurosPipe
  ) {
    // do nothing
  }

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      licensePlate: [null, [Validators.required]],
    })
  }

  blur(): void {
    this.licensePlate.nativeElement.blur()
  }

  search(): void {
    this.total = this.PENDING_TOTAL
    this.subscription.add(
      this.passageService.getTotal(this.searchForm.value.licensePlate).subscribe(
        total => this.total = total
      )
    )
  }

  doPayment(): void {
    const dialog = this.openPaymentDialog()
    this.subscription.add(
      this.kioskApiService.started().pipe(
        mergeMap(started => started ? of({}) : this.kioskApiService.start()),
        mergeMap(_ => this.passageService.createOrder(this.searchForm.value.licensePlate)),
        tap(_ => dialog.componentInstance.waiting()),
        mergeMap(order => zip(
          of(order),
          this.kioskApiService.debit(order.amountInCents, order.ticketNb)
        )),
        mergeMap(([order, debit]) => {
          if (!debit.successful) {
            return throwError("The payment failed.")
          } else {
            return zip(of(order), of(debit))
          }
        }),
        mergeMap(([order, debit]) => {
          return zip(
            this.payOrder(order, debit),
            this.kioskApiService.printBill(this.buildTicket(order, debit))
          )
        })
      ).subscribe(
        _ => {
          dialog.componentInstance.success()
          this.closePaymentDialog(dialog)
        },
        error => {
          console.error(error)
          dialog.componentInstance.error()
          this.closePaymentDialog(dialog)
        }
      )
    )
  }

  payOrder(order: Order, debit: Debit): Observable<any> {
    const transaction: Transaction = {
      id: debit.serverTransactionNb,
      serverNb: debit.bankingServerNb,
      at: debit.transactionDateTime
    }
    return this.passageService.payOrder(order, transaction)
  }

  buildTicket(order: Order, debit: Debit): string {
    const templateData = {
      address: "1440 Route de Cluses, 74138 Bonneville",
      siret: "58205651100105",
      telNb: "04.50.25.20.00",
      ticketNb: order.ticketNb,
      ticketDate: new Date(debit.transactionDateTime).toLocaleDateString(),
      licensePLate: this.searchForm.value.licensePlate,
      products: this.buildProducts(order.products),
      priceNoVAT: "(% text right 'HT  " + this.centsToEurosPipe.transform(order.amountInCentsWithoutTva, '1.2') + "€ ' %)",
      price: "(% text right 'TTC " + this.centsToEurosPipe.transform(order.amountInCents, '1.2') + "€ ' %)"
    }

    var ticket = mustache.render(TICKET_TEMPLATE, templateData)
    return ticket
  }

  buildProducts(products: Product[]): { name: string }[] {
    if (products) {
      return products.map(product => {
        let productPrice = " x1 " + this.centsToEurosPipe.transform(product.amountInCents, '1.2') + "€"
        return { name: this.processTicketLine(product.name, productPrice) }
      })
    } else {
      return []
    }
  }

  processTicketLine(leftPart: string, rightPart: string): string {
    leftPart = leftPart.padEnd(TICKET_WIDTH - 1 - rightPart.length)
    return leftPart + rightPart
  }

  openPaymentDialog(): MatDialogRef<PaymentDialogComponent> {
    let config = {
      disableClose: true,
      width: "calc(100vw - 200px)",
      height: "calc(100vh - 200px)",
      panelClass: "amb-dialog",
    } as MatDialogConfig
    const dialog = this.matDialog.open(PaymentDialogComponent, config)
    this.subscription.add(
      dialog.beforeClosed().subscribe(_ => this.reset())
    )
    return dialog
  }

  closePaymentDialog(dialog: MatDialogRef<PaymentDialogComponent>): void {
    setTimeout(_ => dialog.close(), 300000)
  }

  reset(): void {
    this.total = this.PENDING_TOTAL
    this.searchForm.reset()
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
