import {Component, OnDestroy, OnInit} from "@angular/core";
import {MatDialogRef} from '@angular/material/dialog';
import {KioskApiService} from "../../../services/kiosk/kiosk-api.service";
import {Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {map} from "rxjs/operators";

enum TpeState {
  LOADING,
  WAITING,
  SUCCESS,
  ERROR
}

@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss']
})
export class PaymentDialogComponent implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription()

  TpeState = TpeState

  state = TpeState.LOADING

  paymentInfo: string

  constructor(
    private kioskApiService: KioskApiService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.subscription.add(this.kioskApiService.getPaymentInfos().pipe(
      map(info => info.type ? this.translateService.instant(`PAYMENT_INFO.${info.type}`) : "")
    ).subscribe(message => {
      this.paymentInfo = message
    }))
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  waiting(): void {
    this.state = TpeState.WAITING
  }

  success(): void {
    this.state = TpeState.SUCCESS
  }

  error(): void {
    this.state = TpeState.ERROR
  }
}
