<mat-dialog-content class="kiosk-error-dialog__content" [ngSwitch]="state">
  <ng-container *ngSwitchCase="PrinterState.OFFLINE">
    <mat-icon>offline_bolt</mat-icon>
    <p i18n>L'imprimante ne répond plus.</p>
  </ng-container>
  <ng-container *ngSwitchCase="PrinterState.PAPER_END">
    <mat-icon>build</mat-icon>
    <p i18n>L'imprimante n'a plus de papier, veuillez en remettre.</p>
  </ng-container>
  <ng-container *ngSwitchCase="PrinterState.COVER_OPENED">
    <mat-icon>lock_open</mat-icon>
    <p i18n>Merci de refermer le couvercle de l'imprimante.</p>
  </ng-container>
  <ng-container *ngSwitchCase="PrinterState.OK">
    <mat-icon>check_circle</mat-icon>
    <p i18n>L'imprimante est de nouveau opérationelle.</p>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <mat-icon>error</mat-icon>
    <p i18n>L'imprimante n'est plus en état de fonctionner.</p>
    <p i18n>Merci de contacter le support.</p>
  </ng-container>
</mat-dialog-content>
