import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ConfigService} from "../services/config.service";

@Injectable({
    providedIn: 'root'
})
export class EmulateDevicesGuard implements CanActivate {

    constructor(
      private configService: ConfigService
    ) {
      // do nothing
    }

    canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
      let curEmulatedDevices = this.configService.isEmulateDevices();
      if (curEmulatedDevices) return curEmulatedDevices

      let emulateDevicesValue = route.queryParamMap.get('emulateDevices');
      let emulateDevices = emulateDevicesValue != null;
      this.configService.setEmulateDevices(emulateDevices)

      return true
    }
}
