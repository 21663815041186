<mat-dialog-content class="payment-dialog__content" [ngSwitch]="state">
  <ng-container *ngSwitchCase="TpeState.LOADING">
    <mat-icon>hourglass_top</mat-icon>
    <p i18n>Merci de patienter, votre action a été prise en compte.</p>
  </ng-container>
  <ng-container *ngSwitchCase="TpeState.WAITING">
    <mat-icon>payment</mat-icon>
    <p i18n>Merci de suivre les instructions sur le terminal de paiement.</p>
  </ng-container>
  <ng-container *ngSwitchCase="TpeState.SUCCESS">
    <mat-icon>check_circle</mat-icon>
    <p i18n>Votre paiement a été pris en compte.</p>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <mat-icon>error</mat-icon>
    <p i18n>Votre paiement a échoué.</p>
    <p i18n>Merci de réessayer ou de contacter le support.</p>
  </ng-container>
  <div>{{ paymentInfo }}</div>
</mat-dialog-content>
<mat-dialog-actions class="payment-dialog__actions" *ngIf="state == TpeState.SUCCESS || state == TpeState.ERROR">
  <button mat-button mat-dialog-close>Fermer</button>
</mat-dialog-actions>
