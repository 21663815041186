import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import {ApiAuthGuard} from "./shared/guards/apiAuth.guard";
import {EmulateDevicesGuard} from "./shared/guards/emulate-devices.guard";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    canActivate: [ApiAuthGuard, EmulateDevicesGuard],
    component: HomeComponent,
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [ApiAuthGuard],
  exports: [RouterModule]
})
export class AppRoutingModule { }
