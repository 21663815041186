import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrderDto } from 'src/app/interfaces/dto/order-dto';
import { Order, Transaction } from 'src/app/interfaces/order';
import { InvocationResult } from 'src/app/shared/interfaces/invocation-result';
import { environment } from 'src/environments/environment';
import { webSocket } from "rxjs/webSocket";
import { reconnectWebsocket } from "../../shared/rxjs-operators";

const RECONNECT_INTERVAL_IN_SECONDS = 30;

@Injectable({
  providedIn: 'root'
})
export class PassageService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  getTotal(licensePlate: string): Observable<string> {
    let queryParams = new HttpParams()
      .set('licensePlate', licensePlate)
    return this.httpClient.get(`${environment.ambBaseUrl}/passages/total`, { params: queryParams }).pipe(
      map((res: InvocationResult) => {
        return res.data
      })
    )
  }

  createOrder(licensePlate: string): Observable<Order> {
    let queryParams = new HttpParams()
      .set('licensePlate', licensePlate)
    return this.httpClient.post(`${environment.ambBaseUrl}/passages/orders`, undefined, { params: queryParams }).pipe(
      map((res: InvocationResult) => {
        return res.data
      })
    )
  }

  payOrder(order: Order, transaction: Transaction): Observable<any> {
    const orderDto: OrderDto = {
      amountInCents: order.amountInCents,
      amountInCentsWithoutTva: order.amountInCentsWithoutTva,
      ticketNb: order.ticketNb,
      products: order.products,
      transaction: transaction,
    }
    return this.httpClient.post(`${environment.ambBaseUrl}/passages/orders/pay`, orderDto)
  }

  getLastPassages() {
    return webSocket(environment.passagesWebSocket).pipe(
      reconnectWebsocket(RECONNECT_INTERVAL_IN_SECONDS)
    )
  }
}
