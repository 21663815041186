import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Debit} from 'src/app/interfaces/debit';
import {DebitDto} from 'src/app/interfaces/dto/debit-dto';
import {InvocationResult} from 'src/app/shared/interfaces/invocation-result';
import {environment} from 'src/environments/environment';
import {webSocket} from "rxjs/webSocket";
import {reconnectWebsocket} from "../../shared/rxjs-operators";
import {PrinterInfo} from "../../interfaces/printer.info";
import {PaymentInfo} from "../../interfaces/payment.info";

const RECONNECT_INTERVAL_IN_SECONDS = 10

@Injectable({
  providedIn: 'root'
})
export class KioskApiService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  started(): Observable<boolean> {
    return this.httpClient.get(`${environment.kioskApi.baseUrl}/payment-terminal/started`).pipe(
      map((res: InvocationResult) => {
        return res.data
      })
    )
  }

  start(): Observable<any> {
    return this.httpClient.post(`${environment.kioskApi.baseUrl}/payment-terminal/start`, {})
  }

  debit(amountInCents: number, ticketNb: string): Observable<Debit> {
    const debitDto: DebitDto = {
      amountInCents: amountInCents,
      ticketNb: ticketNb,
    }
    return this.httpClient.post(`${environment.kioskApi.baseUrl}/payment-terminal/debit`, debitDto).pipe(
      map((res: InvocationResult) => {
        return res.data
      })
    )
  }

  getPaymentInfos(): Observable<PaymentInfo> {
    return webSocket(`${environment.kioskApi.websocketBaseUrl}/payment-terminal/messages`)
  }

  printBill(billContent: string): Observable<any> {
    return this.httpClient.post(`${environment.kioskApi.baseUrl}/ticket-printer/print`, { content: billContent, partialCut: true }).pipe(
      map((res: InvocationResult) => {
        return res.data
      })
    )
  }

  getPrinterInfos(): Observable<PrinterInfo> {
    return webSocket(`${environment.kioskApi.websocketBaseUrl}/ticket-printer/messages`).pipe(
      reconnectWebsocket(RECONNECT_INTERVAL_IN_SECONDS)
    )
  }
}
