// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  kioskApi: {
    websocketBaseUrl: "ws://localhost:8080",
    baseUrl: "http://localhost:8080",
    user: "kiosk",
    password: "K1osk28?"
  },
  ambBaseUrl: "https://api.dev.amb.humelab.com",
  mediaBaseUrl: "https://media.dev.humecloud.com",
  passagesWebSocket: "wss://api.dev.amb.humelab.com/passages",
  keycloak: {
    url: "https://auth.dev.common.humelab.com/auth",
    realm: "scr",
    clientId: "amb-catchup-kiosk-app",
    username: "amb-catchup-kiosk-app@humelab.com",
    password: "p7^cd7FJ@8P9JNFm"
  },
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
